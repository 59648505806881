const Url = "https://api.rkfilmsandmcch.com/"

export const URLS = {
  Base: Url,

  //Profile
  getProfile: Url + "mcch/api/admin/getcurrentprofile",
  updateprofile: Url + "mcch/api/admin/editcurrentprofile",
  Changepass: Url + "mcch/api/admin/changepassword",
  UpdateImages: Url + "mcch/api/admin/updateavatar",

  forget: Url + "mcch/api/admin/forgotpassword",
  OTP: Url + "mcch/api/admin/compareotp",
  Resetpass: Url + "mcch/api/admin/resetpassword",

  //Users
  GetUser: Url + "mcch/api/admin/users/getallusers",
  GetUserID: Url + "mcch/api/admin/users/edituserstatus/",
  GetUserSearch: Url + "mcch/api/admin/users/getallusers?searchQuery=",
  GetOneUser: Url + "v1/actinapi/admin/user/getdetails",

  //Banners
  AddBanners: Url + "mcch/api/admin/banner/addbanners",
  GetBanners: Url + "mcch/api/admin/banner/getallbanners",
  GetBannersSearch: Url + "mcch/api/admin/banner/getallbanners?searchQuery=",
  UpdateBanners: Url + "mcch/api/admin/banner/editbanner/",
  InActiveBanners: Url + "mcch/api/admin/banner/deletebanner/",

  //Subscription
  AddSubscription: Url + "mcch/api/admin/subscription/addsubscription",
  GetSubscription: Url + "mcch/api/admin/subscription/getallsubscription",
  GetSubscriptionSearch:
    Url + "mcch/api/admin/subscription/getallsubscription?searchQuery=",
  UpdateSubscription: Url + "mcch/api/admin/subscription/editsubscription/",
  InActiveSubscription: Url + "mcch/api/admin/subscription/deletesubscription/",
  GetoneSubscription: Url + "mcch/api/admin/subscription/getsubsriptionbyid/",

  GetSubscriber: Url + "mcch/api/admin/subscription/getallsubscribers",
  GetSubscriberSearch:
    Url + "mcch/api/admin/subscription/getallsubscribers?searchQuery=",

  //Faqs
  AddFaqs: Url + "mcch/api/admin/faq/add",
  GetFaqs: Url + "mcch/api/admin/faq/get",
  GetFaqsSearch: Url + "mcch/api/admin/faq/get?searchQuery=",
  UpdateFaqs: Url + "mcch/api/admin/faq/edit",
  InActiveFaqs: Url + "mcch/api/admin/faq/delete",

  //GetSettings
  GetSetting: Url + "mcch/api/admin/companypolicy/getcompanypolicies",
  UpdateAbout: Url + "mcch/api/admin/companypolicy/updateaboutus",
  UpdateClaim: Url + "mcch/api/admin/companypolicy/updateclaimpolicy",
  UpdateTerms: Url + "mcch/api/admin/companypolicy/updatetermscondition",
  UpdateRefundPolicy: Url + "mcch/api/admin/companypolicy/updaterefundpolicy",
  UpdatePrivicyPolicy: Url + "mcch/api/admin/companypolicy/updateprivacypolicy",

  //Notifications
  AddNotifications: Url + "mcch/api/admin/notification/addnotification",
  GetNotifications: Url + "mcch/api/admin/notification/showallnotification",
  GetNotificationsSearch:
    Url + "mcch/api/admin/notification/showallnotification?searchQuery=",
  DeleteNotifications: Url + "mcch/api/admin/notification/deletenotification/",

  //Languages
  AddLanguage: Url + "mcch/api/admin/language/addlanguage",
  GetLanguage: Url + "mcch/api/admin/language/getlanguages",
  GetLanguageSearch: Url + "mcch/api/admin/language/getlanguages?searchQuery=",
  UpdateLanguage: Url + "mcch/api/admin/language/editlanguage",
  DeleteLanguage: Url + "mcch/api/admin/language/deletelanguage/",

  //Video
  AddVideo: Url + "mcch/api/admin/video/addvideo",
  GetVideo: Url + "mcch/api/admin/video/getallvideos",
  GetVideoSearch: Url + "mcch/api/admin/video/getallvideos?searchQuery=",
  UpdateVideo: Url + "mcch/api/admin/video/editvideo/",
  DeleteVideo: Url + "mcch/api/admin/video/deletevideo/",
  GetByVideo: Url + "mcch/api/admin/video/getvideobyid",

  //WebSeries
  AddWebSeries: Url + "mcch/api/admin/webseries/addwebseries",
  GetWebSeries: Url + "mcch/api/admin/webseries/getallwebseries",
  GetWebSeriesSearch:
    Url + "mcch/api/admin/webseries/getallwebseries?searchQuery=",
  UpdateWebSeries: Url + "mcch/api/admin/webseries/editwebseries/",
  DeleteWebSeries: Url + "mcch/api/admin/webseries/deletewebseries/",
  GetByWebSeries: Url + "mcch/api/admin/webseries/getwebseriesbyid",
  AddOneWebSeries: Url + "mcch/api/admin/webseries/addepisode",
  GetOneWebSeries: Url + "mcch/api/admin/webseries/getepisodebyid",
  EditOneWebSeries: Url + "mcch/api/admin/webseries/editepisode/",
  DeleteOneWebSeries: Url + "mcch/api/admin/webseries/deleteepisode/",

  GetDashboard: Url + "mcch/api/admin/getdashboard",
}
