import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Modal,
  Form,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { URLS } from "../../../Url"
import { ToastContainer, toast } from "react-toastify"

function LocalNewsView() {
  const history = useHistory()

  const [modal_small1, setmodal_small1] = useState(false)

  function tog_small1() {
    setmodal_small1(!modal_small1)
  }

  const [form, setform] = useState([])

  const [form2, setform2] = useState([])

  const [form3, setform3] = useState([])

  const [form1, setform1] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const Agentid = sessionStorage.getItem("viewwebid")

  useEffect(() => {
    GetOneAgents()
    datass()
  }, [])

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.removeItem("tost");
    } else {
      sessionStorage.removeItem("tost");
    }
  }

  const GetOneAgents = () => {
    const data = {
      id: Agentid,
    }

    var token = datas
    axios
      .post(URLS.GetByWebSeries, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        setform(res.data.webSeries)
        setform3(res.data.episodes)
        // setform2(res.data.webSeries)
      })
  }

  const handleChange1 = e => {
    let myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)
  }

  const handleSubmit1 = e => {
    e.preventDefault()
  }

  const plansid = data => {
    sessionStorage.setItem("planidss", data._id)
    history.push("/EditSeries")
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteState(data)
    }
  }

  const DeleteState = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteOneWebSeries + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            GetOneAgents()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="WebSeries" breadcrumbItem="View WebSeries" />
          <Row>
            <Col>
              <Button
                onClick={() => history.goBack()}
                className="mb-3  m-1 "
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i> Back
              </Button>

              <Link to="/AddSeries">
                <Button
                  className="mb-3  m-1 "
                  style={{ float: "right" }}
                  color="danger"
                >
                  Add Series
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="pt-3">
                    <div className="row justify-content-center">
                      <div className="col-xl-8">
                        <div>
                          <div className="text-center">
                            <div className="mb-4"></div>
                            <h4>{form.videoName}</h4>
                            <h4>{form.languageName}</h4>
                            <p className="text-muted mb-2">
                              <i className="mdi mdi-calendar me-1"></i>
                              {form.releasedYear}
                            </p>
                          </div>
                          <hr />
                          <div className="text-center">
                            <Row>
                              <Col sm={4}>
                                <div>
                                  <p className="text-muted mb-2">Category</p>
                                  <h5 className="font-size-15">
                                    {form.category}
                                  </h5>
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-2">Director</p>
                                  <h5 className="font-size-15">
                                    {form.director}
                                  </h5>
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="mt-4 mt-sm-0">
                                  <p className="text-muted mb-2">Producer</p>
                                  <h5 className="font-size-15">
                                    {form.producer}
                                  </h5>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr />
                          <div className="my-5">
                            <iframe
                              width="100%"
                              height="400px"
                              src={form.videoLink}
                              title="YouTube video player"
                              allowFullScreen
                            ></iframe>
                          </div>
                          <hr />

                          <Row>
                            <Col md={6}>
                              <ul className="list-inline">
                                <li className="list-inline-item mr-5">
                                  <a className="text-muted">
                                    <i
                                      className="bx bx-star align-middle text-muted me-1"
                                      style={{
                                        fontSize: "25px",
                                        color: "yellow",
                                      }}
                                    ></i>
                                    {form.rating}
                                  </a>
                                </li>
                              </ul>
                            </Col>{" "}
                          </Row>
                          <hr />
                          <div className="mt-4">
                            <div className="text-muted font-size-14">
                              <p>{form.description}</p>

                              <blockquote className="p-4 border-light border rounded mb-4">
                                <div className="d-flex">
                                  <div className="me-3">
                                    <i className="bx bxs-quote-alt-left text-dark font-size-24"></i>
                                  </div>
                                  <div>
                                    <p className="mb-0"> {form.timeFrame}</p>
                                  </div>
                                </div>
                              </blockquote>
                            </div>
                          </div>

                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            {form3.map((data, key) => (
              <Col lg={6} key={key}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <div style={{ float: "right" }}>
                          <Button
                            onClick={() => {
                              plansid(data)
                            }}
                            size="sm"
                            className="m-1"
                            outline
                            color="success"
                          >
                            <i
                              style={{ fontSize: " 14px" }}
                              className="bx bx-edit"
                            ></i>
                          </Button>
                          <Button
                            onClick={() => {
                              manageDelete(data)
                            }}
                            style={{
                              padding: "6px",
                              margin: "3px",
                            }}
                            color="danger"
                            outline
                          >
                            <i className="bx bx-trash"></i>
                          </Button>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="pt-3">
                          <div className="row justify-content-center">
                            <div className="col-xl-8">
                              <div>
                                <div className="text-center">
                                  <div className="mb-4"></div>
                                  <h4>{data.episodeName}</h4>
                                </div>
                                <hr />
                                <div className="text-center">
                                  <Row>
                                    <Col sm={6}>
                                      <div className="mt-4 mt-sm-0">
                                        <p className="text-muted mb-2">
                                          {" "}
                                          Episode Number
                                        </p>
                                        <h5 className="font-size-15">
                                          {data.episodeNo}
                                        </h5>
                                      </div>
                                    </Col>
                                    <Col sm={6}>
                                      <div>
                                        <p className="text-muted mb-2">
                                          Status
                                        </p>
                                        <h5 className="font-size-15">
                                          {data.status == "true" ? (
                                            <span className="badge bg-primary">
                                              Active
                                            </span>
                                          ) : (
                                            <span className="badge bg-danger">
                                              InActive
                                            </span>
                                          )}
                                        </h5>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                                <hr />
                                <div className="my-4">
                                  <iframe
                                    width="100%"
                                    height="200px"
                                    src={data.videoLink}
                                    title="YouTube video player"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                                <hr />

                                <div className="mt-4">
                                  <div className="text-muted font-size-14">
                                    <p>{data.description}</p>

                                    <blockquote className="p-4 border-light border rounded mb-4">
                                      <div className="d-flex">
                                        <div className="me-3">
                                          <i className="bx bxs-quote-alt-left text-dark font-size-24"></i>
                                        </div>
                                        <div>
                                          <p className="mb-0">
                                            {" "}
                                            {data.timeFrame}
                                          </p>
                                        </div>
                                      </div>
                                    </blockquote>
                                  </div>
                                </div>

                                <hr />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Modal
            size="lg"
            isOpen={modal_small1}
            toggle={() => {
              tog_small1()
            }}
            centered
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                Add Series
              </h5>
              <button
                onClick={() => {
                  setmodal_small1(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Col md={12}>
                {/* <img src={Trash} width="100%" height="250px"></img> */}
              </Col>
              <Form
                onSubmit={e => {
                  handleSubmit1(e)
                }}
              >
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">
                    Reject Reason <span className="text-danger">*</span>
                  </Label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="basicpill-firstname-input1"
                    placeholder="Enter Reject Reason"
                    required
                    rows={3}
                    name="rejectionReason"
                    value={form1.rejectionReason}
                    onChange={e => {
                      handleChange1(e)
                    }}
                  />
                </div>

                <div style={{ float: "right" }}>
                  <Button
                    onClick={() => {
                      setmodal_small1(false)
                    }}
                    color="danger"
                    type="button"
                  >
                    Cancel <i className="fas fa-times-circle"></i>
                  </Button>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="fas fa-check-circle"></i>
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default LocalNewsView
