import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Dropzone from "react-dropzone"
import axios from "axios"
import { URLS } from "Url"

function AddVendors() {
  const [form, setform] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [formErrors, setFormErrors] = useState({})

  const handleChanges = e => {
    const { name, value } = e.target

    if (name === "imageLink") {
      if (!/^https:\/\/.*/.test(value)) {
        setFormErrors({ ...formErrors, imageLink: "Invalid URL" })
      } else {
        setFormErrors({ ...formErrors, imageLink: "" })
      }
    }

    setform({ ...form, [name]: value })
  }

  const [files, setFiles] = useState([])

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      const fileExtension = file.name.split(".").pop().toLowerCase()
      const allowedExtensions = ["jpg", "jpeg", "png"]

      if (!allowedExtensions.includes(fileExtension)) {
        toast("Invalid file format! Only JPG, JPEG, PNG are allowed.")
        return
      }

      const validatedFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatFileSize(file.size),
      })

      setFiles([validatedFile])
    }
  }
  const formatFileSize = bytes => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }
  const handleSubmit = e => {
    e.preventDefault()

    if (files.length > 0 && !formErrors.imageLink) {
      // Both conditions are satisfied, proceed with Adddealer()
      Adddealer()
    } else {
      // Display appropriate toast messages for validation errors
      if (files.length === 0) {
        toast("Please upload image")
      }
      if (formErrors.imageLink) {
        toast("Please fix the validation errors before submitting.")
      }
    }
  }

  const history = useHistory()
  const Adddealer = () => {
    var token = datas
    const dataArray = new FormData()
    dataArray.append("languageId", form.languageId)
    dataArray.append("videoName", form.videoName)
    dataArray.append("category", form.category)
    dataArray.append("description", form.description)
    dataArray.append("adultContent", form.adultContent)
    dataArray.append("timeFrame", form.timeFrame)
    dataArray.append("videoLink", form.videoLink)
    dataArray.append("releasedYear", form.releasedYear)
    dataArray.append("rating", form.rating)
    dataArray.append("director", form.director)
    dataArray.append("casting", form.casting)
    dataArray.append("producer", form.producer)

    // dataArray.append("latestInterview", form.latestInterview)
    // dataArray.append("latestProgram", form.latestProgram)

    for (let i = 0; i < files.length; i++) {
      dataArray.append("thumbnail", files[i])
    }

    axios
      .post(URLS.AddWebSeries, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            history.push(
              "/WebSeries",
              sessionStorage.setItem(
                "tost",
                "The Webseries was Added successfully"
              )
            )
            clearForm()
            setSelectedOptions("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const handlechange = e => {
    const myform = { ...form }
    myform[e.target.name] = e.target.value
    setform(myform)
  }

  const clearForm = () => {
    setform({
      languageId: "",
      category: "",
      videoName: "",
      description: "",
      adultContent: "",
      timeFrame: "",
      videoLink: "",
      releasedYear: "",
      latestInterview: "",
      latestProgram: "",
    })
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const [states, setstates] = useState([])

  useEffect(() => {
    getstates()
  }, [])

  const getstates = () => {
    var token = datas
    axios
      .post(
        URLS.GetLanguage,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setstates(res.data.languageResult)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="videos" breadcrumbItem="Add WebSeries" />
          <Form
            onSubmit={e => {
              handleSubmit(e)
            }}
          >
            <Row>
              <Col xl="12">
                <Button
                  onClick={history.goBack}
                  className="mb-3"
                  style={{ float: "right" }}
                  color="primary"
                >
                  <i className="far fa-arrow-alt-circle-left"></i>
                  Back
                </Button>
              </Col>
            </Row>

            <Card>
              <CardBody>
                <Row className="mt-2 ">
                  <Col lg="6">
                    <h5
                      className="mb-4"
                      style={{ color: "#0079FF", fontWeight: "bold" }}
                    >
                      Add WebSeries
                    </h5>
                    <div className="mb-3">
                      <Label> Language Name</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.languageId}
                        name="languageId"
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {states.map((data, key) => {
                          return (
                            <option key={key} value={data._id}>
                              {data.language}
                            </option>
                          )
                        })}
                      </select>{" "}
                    </div>

                    <div className="mb-3">
                      <Label> Category Name</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.category}
                        name="category"
                        required
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        {/* <option value="Show">Show</option>
                        <option value="Interview">Interview</option>*/}
                        <option value="Action">Action</option>
                        <option value="Comedy">Comedy</option>
                        <option value="Horror">Horror</option>
                        <option value="Romance">Romance</option>
                        <option value="Drama">Drama</option>
                        <option value="Thriller">Thriller</option>
                        <option value="Science-Fiction">Science-Fiction</option>
                        <option value="Fantasy">Fantasy</option>
                        <option value="Mystery">Mystery</option>
                        <option value="Western">Western</option>
                        <option value="Adventure">Adventure</option>
                        <option value="Musical">Musical</option>
                        <option value="War">War</option>
                        <option value="Biography">Biography</option>
                        <option value="Animation">Animation</option>
                        <option value="Superhero">Superhero</option>
                        <option value="Historical">Historical</option>
                        <option value="Humor">Humor</option>
                        <option value="Others">Others</option>
                      </select>{" "}
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Video Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Video Name"
                        required
                        value={form.videoName}
                        name="videoName"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Video Url <span className="text-danger">*</span>
                      </Label>
                      {/* <textarea
                        type="text"
                        rows="3"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Video Url"
                        required
                        value={form.videoLink}
                        name="videoLink"
                        onChange={e => {
                          handlechange(e)
                        }}
                      /> */}
                      <textarea
                        type="text"
                        rows="3"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Video Link"
                        required
                        value={form.videoLink}
                        name="videoLink"
                        onChange={handleChanges}
                      />
                      {formErrors.imageLink && (
                        <div className="error-message">
                          {formErrors.imageLink}
                        </div>
                      )}
                    </div>
                  </Col>

                  {/* <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}>Thumbnail Image</h5>

                      <div className="w-50 m-auto">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()}  />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                          <span>
                            Image format - jpg, png, jpeg, gif Image Size -
                            maximum size 2 MB Image Ratio - 1:1
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col> */}

                  <Col lg="6">
                    <div className="text-center m-4">
                      <h5 style={{ fontWeight: "bold" }}>Thumbnail Image </h5>

                      <div className="w-50 m-auto">
                        <Dropzone onDrop={handleDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone" id="ssd">
                              <div
                                {...getRootProps()}
                                className="dz-message needsclick mt-2"
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>upload File.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>

                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {files.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="40"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                          <span>
                            Image format - jpg, png, Image Size - maximum size 2
                            MB Image Ratio - 1:1
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Director <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Director Name"
                        required
                        value={form.director}
                        name="director"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Producer Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Producer Name"
                        required
                        value={form.producer}
                        name="producer"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label>Rating</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.rating}
                        name="rating"
                        required
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-select"
                      >
                        {" "}
                        <option value="">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label> Adult Content</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.adultContent}
                        name="adultContent"
                        required
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-select"
                      >
                        {" "}
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Time Frame <span className="text-danger">*</span>
                      </Label>
                      <input
                        id="settime"
                        type="time"
                        step="1"
                        className="form-control"
                        placeholder="Enter Time Frame"
                        required
                        value={form.timeFrame}
                        name="timeFrame"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Released Year <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter Released Year"
                        required
                        value={form.releasedYear}
                        name="releasedYear"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>{" "}
                  </Col>

                  {/* <Col md={6}>
                    <div className="mb-3">
                      <Label>Latest Interview</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.latestInterview}
                        name="latestInterview"
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-select"
                      >
                        {" "}
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label> Latest Program</Label>{" "}
                      <span className="text-danger">*</span>
                      <select
                        value={form.latestProgram}
                        name="latestProgram"
                        onChange={e => {
                          handlechange(e)
                        }}
                        className="form-select"
                      >
                        {" "}
                        <option value="">Select</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </Col> */}
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Casting <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="6"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Casting"
                        required
                        value={form.casting}
                        name="casting"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">
                        Description <span className="text-danger">*</span>
                      </Label>
                      <textarea
                        type="text"
                        rows="6"
                        className="form-control "
                        id="basicpill-firstname-input1"
                        placeholder="Enter Description"
                        required
                        value={form.description}
                        name="description"
                        onChange={e => {
                          handlechange(e)
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <Col md={12}>
                <div className="mb-4 mt-2" style={{ float: "right" }}>
                  <Button className="m-1" color="primary" type="submit">
                    Submit <i className="bx bx-check-circle"></i>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>{" "}
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default AddVendors
