import React from "react"
import { CardBody, CardHeader, Container, Row, Col, Card } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import logo01 from "../../assets/images/logos.png"
import { Link } from "react-router-dom"

function Faq() {
  return (
    <div style={{ width: "100%" }}>
      <div>
        <nav style={{ background: "#7B55E0" }} className="navbar">
          <a className="navbar-brand navbar-brand" href="#">
            <div className="mt-2 mb-2 ml-4">
              <img
                style={{ width: "165px", height: "40px", marginLeft: "20px" }}
                src={logo01}
                alt=""
              />
            </div>
          </a>
        </nav>
      </div>
      <Container fluid>
        <div className="page-content">
          <Breadcrumbs title="MCCH" breadcrumbItem="Privacy Policy" />
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div style={{ float: "right" }}></div>
                  <div>
                    <div>
                      <h5>Privacy Policy</h5>
                      <div className="mt-5">
                        <b>User Eligibility</b>
                        <p>
                          You must be at least 18 years old or the age of
                          majority in your jurisdiction to use the App. By using
                          the App, you represent and warrant that you meet the
                          eligibility criteria.
                        </p>
                        <b className="mt-2">Account Registration</b>
                        <p>
                          To use certain features of the App, you may be
                          required to create an account. You are responsible for
                          maintaining the confidentiality of your account
                          credentials and for any activities that occur under
                          your account. You agree to provide accurate and
                          complete information during the registration process.
                        </p>
                        <b>Content</b>
                        <p>
                          a.<b className="mt-2">Content Availability:</b> The
                          content available on the App may change from time to
                          time and may vary by location. We do not guarantee the
                          availability of any specific content.
                        </p>
                        <p>
                          b.<b className="mt-2">Intellectual Property:</b> All
                          content, including but not limited to text, images,
                          videos, and graphics, is protected by copyright and
                          other intellectual property rights. You may not
                          reproduce, distribute, or create derivative works
                          without our prior written consent.
                        </p>
                        <b className="mt-2"> User Conduct</b>
                        You agree to use the App in a manner consistent with all
                        applicable laws and regulations and not to:
                        <p>
                          a. Use the App for any illegal or unauthorized
                          purpose.
                        </p>
                        <p>
                          b. Distribute spam, chain letters, or other
                          unsolicited communications.
                        </p>
                        <p>
                          c. Interfere with or disrupt the App's operation or
                          the servers and networks connected to the App.
                        </p>
                        <p>
                          d. Attempt to gain unauthorized access to the App or
                          any user accounts.
                        </p>
                        <p>
                          e. Impersonate any person or entity or misrepresent
                          your affiliation with any person or entity.
                        </p>
                        <b className="mt-2">Privacy</b>
                        <p>
                          Our Privacy Policy, available on the App, outlines how
                          we collect, use, and protect your personal
                          information. By using the App, you consent to the
                          practices described in the Privacy Policy.
                        </p>
                        <b className="mt-2">Termination</b>
                        <p>
                          We reserve the right to terminate your access to the
                          App, with or without cause and without notice, at our
                          sole discretion.
                        </p>
                        <b className="mt-2">Disclaimer</b>
                        <p>
                          The App is provided "as is" and "as available" without
                          warranties of any kind. We do not guarantee that the
                          App will be error-free or uninterrupted.
                        </p>
                        <b className="mt-2">Limitation of Liability</b>
                        <p>
                          To the extent permitted by applicable law, we shall
                          not be liable for any indirect, incidental, special,
                          consequential, or punitive damages, or any loss of
                          profits or revenues.
                        </p>
                        <b className="mt-2">Governing Law</b>
                        <p>
                          These Terms are governed by and construed in
                          accordance with the laws of [Your Jurisdiction].
                        </p>
                        <b className="mt-2">Changes to Privacy Policy</b>
                        <p>
                          We reserve the right to update these Terms at any
                          time. Your continued use of the App after any changes
                          to the Terms will constitute your acceptance of the
                          revised Terms.
                        </p>
                        <b className="mt-2">Contact Information</b>
                        <p>
                          If you have any questions or concerns about these
                          Terms, please contact us at [Your Contact
                          Information]. These are sample Privacy Policy for an
                          OTT application. Make sure to customize them according
                          to your specific needs, and it is highly recommended
                          to consult with legal counsel to ensure compliance
                          with applicable laws and regulations.
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="faqfoot">
        <nav style={{ background: "#7B55E0" }}>
          <div className="justify-content-center text-center p-1">
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-3">
                <Link className="text-white p-2" to="/login">
                  Login
                </Link>
                <Link className="text-white p-2" to="/Privacy">
                  Privacy Policy
                </Link>
                <Link className="text-white p-2" to="/Terms">
                  Terms & Conditions
                </Link>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Faq
