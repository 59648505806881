import React, { useState } from "react"
import { Row, Col, Card, Input, Button, Label, Form } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import { URLS } from "../../../Url"

const Addplan = () => {
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const [inputList, setInputList] = useState([{ features: "" }])

  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleAddClick = () => {
    setInputList([...inputList, { features: "" }])
  }

  const [form, setform] = useState([])

  let history = useHistory()

  const handleChange = e => {
    const myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const Addplans = () => {
    const token = datas

    const dataArray = {
      planName: form.planName,
      features: inputList,
      payment: form.payment,
      trailPeriod: form.trailPeriod,
      package: form.package,
      offerPercent: form.offerPercent,
      amount: form.amount,
    }

    axios
      .post(URLS.AddSubscription, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setform("")
            history.push(
              "/SubscriptionList",
              sessionStorage.setItem("tost", "The subscription was successfully added")
            )
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const formsubmit = e => {
    e.preventDefault()
    Addplans()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="MCCH" breadcrumbItem="Add Subscription" />

          <Row>
            <Col xl="12">
              <Button
                onClick={history.goBack}
                className="mb-3"
                style={{ float: "right" }}
                color="primary"
              >
                <i className="far fa-arrow-alt-circle-left"></i>
                Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="p-4">
                <Form
                  onSubmit={e => {
                    formsubmit(e)
                  }}
                >
                  <Row>
                    <Col md="4" className="mt-2">
                      <Label>Plan Name</Label>
                      <span className="text-danger">*</span>
                      <Input
                        onChange={e => {
                          handleChange(e)
                        }}
                        value={form.planName}
                        name="planName"
                        required
                        type="text"
                        placeholder="Enter Name"
                      />
                    </Col>

                    <Col md="4" className="mt-2">
                      <Label>Payment Type</Label>
                      <span className="text-danger">*</span>
                      <select
                        onChange={e => {
                          handleChange(e)
                        }}
                        value={form.payment}
                        name="payment"
                        className="form-select"
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="Paid">Paid</option>
                        <option value="Free">Free</option>
                      </select>
                    </Col>
                    {form.payment == "Paid" ? (
                      <>
                        <Col md="4" className="mt-2">
                          <Label>Package Type</Label>
                          <span className="text-danger">*</span>
                          <select
                            onChange={e => {
                              handleChange(e)
                            }}
                            value={form.package}
                            name="package"
                            className="form-select"
                            required
                          >
                            <option value="">Select Type</option>
                            <option value="1-Month">1 Month</option>
                            <option value="3-Months">3 Month</option>
                            <option value="6-Months">6 Month</option>
                            <option value="9-Months">9 Month</option>
                            <option value="12-Months">12 Month</option>
                          </select>
                        </Col>

                        <Col md="4" className="mt-3">
                          <Label>
                            {form.package == "1-Month" ? <>1 </> : <></>}
                            {form.package == "3-Months" ? <>3 </> : <></>}
                            {form.package == "6-Months" ? <>6 </> : <></>}
                            {form.package == "9-Months" ? <>9 </> : <></>}
                            {form.package == "12-Months" ? <>12 </> : <></>}
                            Month Price
                          </Label>
                          <span className="text-danger">*</span>
                          <Input
                            onChange={e => {
                              handleChange(e)
                            }}
                            name="amount"
                            required
                            type="number"
                            value={form.amount}
                            placeholder="Enter Price"
                          />
                        </Col>

                        <Col md="4" className="mt-3">
                          <Label>Offer Percent</Label>
                          <span className="text-danger">*</span>
                          <Input
                            onChange={e => {
                              handleChange(e)
                            }}
                            name="offerPercent"
                            required
                            type="number"
                            value={form.offerPercent}
                            placeholder="Enter Offer Percent"
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col md="4" className="mt-2">
                          <Label>Trial Period</Label>
                          <span className="text-danger">*</span>
                          <Input
                            onChange={e => {
                              handleChange(e)
                            }}
                            name="trailPeriod"
                            required
                            value={form.trailPeriod}
                            type="number"
                            placeholder="Enter Trial Period"
                          />
                        </Col>
                      </>
                    )}

                    <Col md={8} className="mt-3">
                      <div>
                        <Label>Plan features</Label>
                        <Row>
                          {inputList.map((x, i) => {
                            return (
                              <>
                                <Row>
                                  <div key={i} className="box row">
                                    <Col md="7" sm="12" className="mb-1">
                                      <Input
                                        type="text"
                                        required
                                        name="features"
                                        placeholder="Enter Plan Features"
                                        value={x.features}
                                        onChange={e => handleInputChange(e, i)}
                                      />
                                    </Col>

                                    <Col sm="2">
                                      <div className="btn-box">
                                        {inputList.length !== 1 && (
                                          <button
                                            className="mr10 btn btn-outline-danger btn-sm m-1"
                                            type="button"
                                            onClick={() => handleRemoveClick(i)}
                                          >
                                            Remove
                                            <i className="bx bx-x-circle"></i>
                                          </button>
                                        )}
                                        {inputList.length - 1 === i && (
                                          <button
                                            className="btn btn-sm btn-outline-info m-1"
                                            onClick={handleAddClick}
                                          >
                                            Add
                                            <i className="bx bx-plus-circle"></i>
                                          </button>
                                        )}
                                      </div>
                                    </Col>
                                  </div>
                                </Row>
                              </>
                            )
                          })}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-end">
                  <Button className="m-1" color="primary" type="submit">
                      Submit <i className="bx bx-check-circle"></i>
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default Addplan
