import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Modal,
  ModalBody,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer, toast } from "react-toastify"
import { Link, useHistory } from "react-router-dom"
import trash from "../../../assets/images/loders.gif"
import ReactPaginate from "react-paginate"
import { URLS } from "../../../Url"
import axios from "axios"

const ResponsiveTables = () => {
  const [show1, setshow1] = useState(false)
  const toggle = () => setshow1(!show1)

  const [show2, setshow2] = useState(false)
  const toggle2 = () => setshow2(!show2)

  const [form1, setform1] = useState([])
  const [form2, setform2] = useState([])

  const [plans, setplans] = useState([])
  const history = useHistory()
  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const getPlans = () => {
    var token = datas
    axios
      .post(
        URLS.GetVideo,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setplans(res.data.videoResult)
      })
  }

  const plansid = data => {
    sessionStorage.setItem("planid", data._id)
    history.push("/EditVideos")
  }

  const datapass = data => {
    setform2(data)
    setshow1(true)
  }

  const getPlandelete = () => {
    const params = form2._id

    var token = datas
    axios
      .put(
        URLS.DeletePlan + params,
        { status: "inactive" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setshow1(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const datapass1 = data => {
    setform2(data)
    setshow2(true)
  }

  const getPlandelete1 = () => {
    const params = form2._id

    var token = datas
    axios
      .put(
        URLS.DeletePlan + params,
        { status: "active" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
            setshow2(false)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const planssearch = e => {
    const myUser = { ...form1 }
    myUser[e.target.name] = e.target.value
    setform1(myUser)

    const token = datas

    axios
      .post(
        URLS.GetVideoSearch + `${e.target.value}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          if (res.status === 200) {
            setplans(res.data.videoResult)
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const datass = () => {
    const location = sessionStorage.getItem("tost")
    if (location != "") {
      toast(location)
      sessionStorage.clear()
    } else {
      sessionStorage.clear()
    }
  }

  useEffect(() => {
    getPlans()
    datass()
  }, [])

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = plans.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(plans.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      DeleteState(data)
    }
  }

  const DeleteState = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteVideo + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getPlans()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="MCCH" breadcrumbItem="Videos list" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Link to="/AddVideos">
                        <Button color="primary">
                          Add Video <i className="bx bx-plus-circle"></i>
                        </Button>
                      </Link>
                    </Col>
                    <Col>
                      <div style={{ float: "right" }}>
                        <Input
                          name="search"
                          value={form1.search}
                          onChange={planssearch}
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin mt-4 table-responsive">
                    <Table hover className="table table-bordered mb-4 mt-5">
                      <thead>
                        <tr className="text-center">
                          <th>SlNo</th>
                          <th>Video Thumbnail</th>
                          <th>Language Name</th>
                          <th>Video Name</th>
                          <th>Video Link</th>
                          <th>Video Description</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lists.map((data, key) => (
                          <tr key={key} className="text-center">
                            <th>{(pageNumber - 1) * 5 + key + 6}</th>
                            <td>
                              {" "}
                              <img
                                style={{ width: "100px" }}
                                src={URLS.Base + data.thumbnail}
                                alt=""
                                className="img-fluid"
                              />
                            </td>
                            <td>{data.languageName}</td>
                            <td>{data.videoName}</td>
                            <td>
                              <iframe
                                width="90%"
                                height="150px"
                                src={data.videoLink}
                                title="YouTube video player"
                                allowFullScreen
                              ></iframe>
                            </td>
                            <td>{data.description.slice(0, 40)}</td>
                            <td>
                              {data.status == "true" ? (
                                <span className="badge bg-primary">Active</span>
                              ) : (
                                <span className="badge bg-danger">
                                  InActive
                                </span>
                              )}
                            </td>

                            <td>
                              <Button
                                onClick={() => {
                                  plansid(data)
                                }}
                                size="sm"
                                className="m-1"
                                outline
                                color="success"
                              >
                                <i
                                  style={{ fontSize: " 14px" }}
                                  className="bx bx-edit"
                                ></i>
                              </Button>

                              <Button
                                onClick={() => {
                                  manageDelete(data)
                                }}
                                style={{
                                  padding: "6px",
                                  margin: "3px",
                                }}
                                color="danger"
                                outline
                              >
                                <i className="bx bx-trash"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div
                      className="d-flex mt-3 mb-1"
                      style={{ float: "right" }}
                    >
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                        total={lists.length}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </div>
      </div>
      <Modal size="sm" isOpen={show1} toggle={toggle} centered>
        <ModalBody>
          <div>
            <div className="text-center">
              <img style={{ width: "280px" }} src={trash} />
            </div>
            <h5 className="text-center">Do you want InActive</h5>
            <div className="text-end mt-2">
              <Button
                onClick={() => {
                  getPlandelete()
                }}
                type="button"
                color="danger m-1"
                outline
              >
                Yes <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={toggle}
                color="secondary m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal size="sm" isOpen={show2} toggle={toggle2} centered>
        <ModalBody>
          <div>
            <div className="text-center">
              <img style={{ width: "280px" }} src={trash} />
            </div>
            <h5 className="text-center">Do you want Active</h5>
            <div className="text-end mt-2">
              <Button
                onClick={() => {
                  getPlandelete1()
                }}
                type="button"
                color="danger m-1"
                outline
              >
                Yes <i className="bx bx-check-circle"></i>
              </Button>
              <Button
                type="button"
                onClick={toggle2}
                color="secondary m-1"
                outline
              >
                Cancel <i className="bx bx-x-circle"></i>
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ResponsiveTables
