import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import ReactPaginate from "react-paginate"
import axios from "axios"
import { URLS } from "Url"

const Dashboard = props => {
  const [form, setform] = useState([])
  const [customers, setcustomers] = useState([])
  const [graphs, setgraph] = useState([])

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  useEffect(() => {
    getAgent()
  }, [])

  const getAgent = () => {
    var token = datas
    axios
      .post(
        URLS.GetDashboard,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setform(res.data.totalCount)
        setgraph(res.data.graph)
        setcustomers(res.data.users)
      })
  }

  const reports = [
    {
      title: "Total Users",
      iconClass: "bx bx-user-check",
      description: form.totalUsers,
    },
    {
      title: "Total Videos",
      iconClass: "bx bx-user-circle",
      description: form.totalVideos,
    },
    {
      title: "Total Web series",
      iconClass: "bx-archive-in",
      description: form.toatalWebseries,
    },
    {
      title: "Total Subscribes",
      iconClass: "bx-copy-alt",
      description: form.totalSubscription,
    },
  ]

  const options = {
      chart: {
        toolbar: { show: false },
        zoom: { enabled: false },
        type: "line",
        dropShadow: {
          enabled: true,
          top: 18,
          left: 2,
          blur: 5,
          opacity: 0.2,
        },
        offsetX: -10,
      },
      stroke: {
        curve: "smooth",
        width: 4,
      },
      grid: {
        borderColor: "#000",
        padding: {
          top: -20,
          bottom: 5,
          left: 20,
        },
      },
      legend: {
        show: false,
      },
      colors: ["#7B55E0"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          inverseColors: false,
          gradientToColors: [props.primary],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 10000, 10000, 10000],
        },
      },
      markers: {
        size: 0,
        hover: {
          size: 5,
        },
      },
      xaxis: {
        labels: {
          offsetY: 5,
          style: {
            colors: "#e9c686",
            fontSize: "0.857rem",
            fontFamily: "Montserrat",
          },
        },
        axisTicks: {
          show: false,
        },
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        axisBorder: {
          show: false,
        },
        tickPlacement: "on",
      },
      yaxis: {
        tickAmount: 8,
        labels: {
          style: {
            colors: "#e9c686",
            fontSize: "0.857rem",
            fontFamily: "Montserrat",
          },
          formatter(val) {
            return val > 999 ? `${val / 1000}k` : val.toFixed(0)
          },
        },
      },
      tooltip: {
        x: { show: false },
      },
    },
    series = [
      {
        name: "Users",
        data: graphs,
      },
    ]

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = customers.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(customers.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("MCCH")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="12">
              <Row>
                {reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <div className="clearfix">
                    <h4 className="card-title mb-4">Users</h4>
                  </div>
                  <Row>
                    <Col lg="12">
                      <div id="line-chart" dir="ltr">
                        <ReactApexChart
                          series={series}
                          options={options}
                          type="line"
                          height={320}
                          className="apex-charts"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div>
                <Card>
                  <CardBody>
                    <h5 className="mt-3 mb-5">Latest Users</h5>
                    <div className="table-rep-plugin mt-4">
                      <Table hover bordered responsive>
                        <thead className="bg-light">
                          <tr className="text-center">
                            <th>SlNo</th>
                            <th>Users Name </th>
                            <th>Users Phone</th>
                            <th>Users Email</th>
                            <th>Subscription Details</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lists.map((data, key) => (
                            <tr key={key} className="text-center">
                              <th>{(pageNumber - 1) * 5 + key + 6}</th>
                              <td>{data.name}</td>
                              <td>{data.phone}</td>
                              <td>{data.email}</td>
                              <td>
                                {data.subscribed == false ? (
                                  <>free</>
                                ) : (
                                  <>Paid</>
                                )}{" "}
                              </td>
                              <td>
                                {data.status == false ? (
                                  <>InActive</>
                                ) : (
                                  <>Active</>
                                )}{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Col sm="12">
                        <div
                          className="d-flex mt-3 mb-1"
                          style={{ float: "right" }}
                        >
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
