import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Table,
  Label,
  Form,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import ReactPaginate from "react-paginate"
import { ToastContainer, toast } from "react-toastify"
import Select from "react-select"
import { URLS } from "../../Url"
import gig from "../../assets/images/loders.gif"

const Notifications = () => {
  const [form, setform] = useState([])

  const [Noti, setNoti] = useState([])

  const [customer, setcustomer] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [Files, setFiles] = useState("")

  const changeHandler = e => {
    const file = e.target.files
    var ext = file[0].name.split(".").pop()
    var type = ext
    if (type == "jpg" || type == "jpeg" || type == "png") {
      setFiles(e.target.files)
    } else {
      e.target.value = null
      toast("file format not supported.Pls choose Image")
    }
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)
  var datas = data.token

  const handleChange = e => {
    let myUser = { ...form }
    myUser[e.target.name] = e.target.value
    setform(myUser)
  }

  const getNotifications = () => {
    var token = datas
    axios
      .post(
        URLS.GetNotifications,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.notifyResult)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getNotifications()
    getactivecustomers()
  }, [])

  const getactivecustomers = () => {
    var token = datas

    axios
      .post(
        URLS.GetUser,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        res => {
          setcustomer(res.data.users)
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const [selectedMulti, setselectedMulti] = useState([])

  function handleMulti(data) {
    setselectedMulti(data)
  }

  const options = customer.map(data => ({
    value: data._id,
    label: data.name,
  }))

  const addnotifi = () => {
    var token = datas

    const dataArray = new FormData()
    dataArray.append("title", form.title)

    if (form.userList == "All") {
      dataArray.append("userList", JSON.stringify("ALL"))
    } else {
      dataArray.append("userList", JSON.stringify(selectedMulti))
    }
    dataArray.append("description", form.description)
    dataArray.append("url", form.url)

    for (let i = 0; i < Files.length; i++) {
      dataArray.append("image", Files[i])
    }
    axios
      .post(URLS.AddNotifications, dataArray, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            setIsLoading(true)
            getNotifications()
            clearForm()
            setselectedMulti("")
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const deletenoti = data => {
    var token = datas
    var remid = data._id
    axios
      .delete(URLS.DeleteNotifications + remid, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(
        res => {
          if (res.status === 200) {
            toast(res.data.message)
            getNotifications()
          }
        },
        error => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message)
          }
        }
      )
  }

  const manageDelete = data => {
    const confirmBox = window.confirm("Do you really want to Delete?")
    if (confirmBox === true) {
      deletenoti(data)
    }
  }

  const [error, setError] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    if (form.userList == "All") {
      addnotifi()
    }

    if (selectedMulti.length == 0) {
      setError("Please select at least one option.")
    } else {
      addnotifi()
    }
  }

  const clearForm = () => {
    setform({
      title: "",
      description: "",
      userList: "",
      url:"",

    })
    setFiles({
      image:""
    })
  }

  const [forms, setforms] = useState([])

  const handlechange = e => {
    let myUser = { ...forms }
    myUser[e.target.name] = e.target.value
    setforms(myUser)
    var token = datas
    axios
      .post(
        URLS.GetNotificationsSearch + `${e.target.value}`,
        {},

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(res => {
        setNoti(res.data.notifyResult)
      })
  }

  const [listPerPage] = useState(5)
  const [pageNumber, setPageNumber] = useState(0)

  const pagesVisited = pageNumber * listPerPage
  const lists = Noti.slice(pagesVisited, pagesVisited + listPerPage)
  const pageCount = Math.ceil(Noti.length / listPerPage)
  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  var gets = localStorage.getItem("authUser")
  var data = JSON.parse(gets)

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="MCCH" breadcrumbItem="Notifications" />

          <Row>
            <Col md={4}>
              <Card className="p-4">
                <h5>Add Notification</h5>

                <Form
                  onSubmit={e => {
                    handleSubmit(e)
                  }}
                >
                  <div>
                    <div className="mt-3">
                      <Label>Title</Label>{" "}
                      <span className="text-danger">*</span>
                      <Input
                        value={form.title}
                        onChange={e => {
                          handleChange(e)
                        }}
                        name="title"
                        required
                        type="text"
                        placeholder="Enter Title"
                      />
                    </div>
                    <div className="mt-3">
                      <Label>Url</Label> <span className="text-danger">*</span>
                      <Input
                        value={form.url}
                        onChange={e => {
                          handleChange(e)
                        }}
                        name="url"
                        required
                        type="url"
                        placeholder="Enter Url"
                      />
                    </div>
                    <div className="mt-3">
                      <Label for="basicpill-firstname-input1">
                        Image <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-firstname-input1"
                        placeholder="Enter image"
                        required
                        name="image"
                        value={Files.image}
                        onChange={changeHandler}
                      />{" "}
                    </div>

                    <div className="mt-3">
                      <Label for="basicpill-firstname-input1">
                        All
                        <span className="text-danger">*</span>
                      </Label>

                      <select
                        value={form.userList}
                        name="userList"
                        onChange={e => {
                          handleChange(e)
                        }}
                        className="form-select"
                        required
                      >
                        <option value="">Select</option>
                        <option value="All">All Customer's</option>
                        <option value="USER">Single Customer</option>
                      </select>
                    </div>

                    {form.userList == "USER" ? (
                      <div className="mt-3">
                        <Label>Customers</Label>
                        <span className="text-danger">*</span>
                        <Select
                          name="userList"
                          value={selectedMulti}
                          onChange={handleMulti}
                          options={options}
                          required
                          isMulti
                        />
                       {error && (
                        <div className="error">{error}</div>
                      )}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mt-3">
                      <Label>Description</Label>{" "}
                      <span className="text-danger">*</span>
                      <textarea
                        className="form-control"
                        value={form.description}
                        onChange={e => {
                          handleChange(e)
                        }}
                        name="description"
                        required
                        type="text"
                        placeholder="Description"
                      />
                    </div>
                  </div>

                  <div className="text-end mt-3">
                    <Button className="m-1" color="primary" type="submit">
                      Submit <i className="bx bx-check-circle"></i>
                    </Button>
                  </div>
                </Form>
              </Card>
            </Col>

            <Col md={8}>
              <Card>
                {isLoading == true ? (
                  <>
                    <div
                      style={{ zIndex: "9999999999999", height: "420px" }}
                      className="text-center mt-5 pt-5"
                    >
                      <img src={gig} height="140px"></img>
                      <div>Loading......</div>
                    </div>
                  </>
                ) : (
                  <>
                    <CardBody>
                      <Row>
                        <Col></Col>
                        <Col>
                          <div style={{ float: "right" }}>
                            <Input
                              name="search"
                              value={forms.search}
                              onChange={handlechange}
                              type="search"
                              placeholder="Search..."
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="table-rep-plugin mt-4 table-responsive">
                        <Table hover bordered responsive>
                          <thead>
                            <tr>
                              <th>Sl.No</th>
                              <th>Image </th>
                              <th>Title</th>
                              <th>Url </th>
                              <th>Description</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lists.map((data, key) => (
                              <tr key={key}>
                                <th scope="row">
                                  {(pageNumber - 1) * 5 + key + 6}
                                </th>
                                <td>
                                  {" "}
                                  <img
                                    style={{ width: "100px" }}
                                    src={URLS.Base + data.image}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </td>
                                <td>{data.title}</td>
                                <td>{data.url.slice(0, 20)}</td>
                                <td>{data.description.slice(0, 20)}</td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      manageDelete(data)
                                    }}
                                    size="sm"
                                    className="m-1"
                                    outline
                                    color="danger"
                                  >
                                    <i
                                      style={{ fontSize: " 14px" }}
                                      className="bx bx-trash"
                                    ></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </CardBody>{" "}
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  )
}

export default Notifications
