import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Otp from "pages/Authentication/Otp"
import Setpwd from "pages/Authentication/Setpwd"

//dashboard
import Dashboard from "../pages/Dashboard/index"

//subscriptions
import AddSubscription from "pages/Mcch/Subscriptions/AddSubscription"
import SubscriptionList from "pages/Mcch/Subscriptions/SubscriptionList"
import EditSubscriptions from "pages/Mcch/Subscriptions/EditSubscriptions"

//Videos
import AddVideos from "pages/Mcch/Videos/AddVideos"
import VideosList from "pages/Mcch/Videos/VideosList"
import EditVideos from "pages/Mcch/Videos/EditVideos"
import ViewVideo from "pages/Mcch/Videos/ViewVideo"

//WebSeries
import AddWebSeries from "pages/Mcch/WebSeries/AddWebSeries"
import WebSeries from "pages/Mcch/WebSeries/WebSeries"
import EditWebSeries from "pages/Mcch/WebSeries/EditWebSeries"
import ViewWebseries from "pages/Mcch/WebSeries/ViewWebseries"
import AddSeries from "pages/Mcch/WebSeries/AddSeries"
import EditSeries from "pages/Mcch/WebSeries/EditSeries"

//Users
import UsersList from "pages/Mcch/Users/UsersList"

//Notifications
import Notifications from "pages/Mcch/Notifications"

//Banners
import Banners from "pages/Mcch/Banners"

//Settings
import About from "pages/Mcch/Settings/About"
import PrivacyPolicy from "pages/Mcch/Settings/PrivacyPolicy"
import TermsandConditions from "pages/Mcch/Settings/TermsandConditions"
import RefundPolicy from "pages/Mcch/Settings/RefundPolicy"
import Languages from "pages/Mcch/Settings/Languages"
import Faqs from "pages/Mcch/Settings/Faqs"
import Claimpolicy from "pages/Mcch/Settings/Claimpolicy"
import ContactUs from "pages/Mcch/Settings/ContactUs"

import Privacy from "pages/outside/Privacy"
import Terms from "pages/outside/Terms"

import Subscriberlist from "pages/Mcch/Subscriptions/Subscriberlist"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //subscriptions
  { path: "/AddSubscription", component: AddSubscription },
  { path: "/SubscriptionList", component: SubscriptionList },
  { path: "/EditSubscriptions", component: EditSubscriptions },

  { path: "/Subscriberlist", component: Subscriberlist },


  //WebSeries
  { path: "/AddWebSeries", component: AddWebSeries },
  { path: "/WebSeries", component: WebSeries },
  { path: "/EditWebSeries", component: EditWebSeries },
  { path: "/ViewWebseries", component: ViewWebseries },
  { path: "/AddSeries", component: AddSeries },
  { path: "/EditSeries", component: EditSeries },

  //Videos
  { path: "/AddVideos", component: AddVideos },
  { path: "/VideosList", component: VideosList },
  { path: "/EditVideos", component: EditVideos },
  { path: "/ViewVideo", component: ViewVideo },

  //Users
  { path: "/UsersList", component: UsersList },

  //Notifications
  { path: "/Notifications", component: Notifications },

  //Banners
  { path: "/Banners", component: Banners },

  //Settings
  { path: "/About", component: About },
  { path: "/PrivacyPolicy", component: PrivacyPolicy },
  { path: "/TermsandConditions", component: TermsandConditions },
  { path: "/Languages", component: Languages },
  { path: "/RefundPolicy", component: RefundPolicy },
  { path: "/Faqs", component: Faqs },
  { path: "/ContactUs", component: ContactUs },
  { path: "/Claimpolicy", component: Claimpolicy },

  //Settings
  { path: "/profile", component: UserProfile },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/otp", component: Otp },
  { path: "/setpassword", component: Setpwd },
  { path: "/Privacy", component: Privacy },
  { path: "/Terms", component: Terms },
]

export { publicRoutes, authProtectedRoutes }
